import { AuthedFetch, CONTENT_GRID_SERVICE_URL } from '@avenue-8/platform-shared-util-frontend'
import { AgentCategoryToolsDto } from '../../../domain/agent-category-tools.dto'
import { ToolsService } from './tools.service.type'

export class ToolsServicesImpl implements ToolsService {
  async getAgentTools(): Promise<AgentCategoryToolsDto[]> {
    const data = await AuthedFetch(`${CONTENT_GRID_SERVICE_URL}/home-screen-categories`, {
      hideAgentId: true,
    }).then((response) => response.json())

    return data
  }
}
