import {
  AuthProvider,
  FeatureFlagsProvider,
  HeapProvider,
  WebviewBridgeProvider,
  UserDataProvider,
  initDatadogRum,
} from '@avenue-8/platform-shared-util-frontend'
import { theme } from '@avenue-8/platform-style-util-frontend'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { TeamsContextProvider } from './contexts/teams.context'
import { ToolsContextProvider } from './contexts/tools.context'
import LoginRedirect from './modules/home/components/login-redirect'
import { Unauthorized } from './modules/home/components/unauthorized'
import { MainLayout } from './modules/shared/components/main-layout/main-layout'
import { TeamsModule } from './modules/teams/teams.module'
import { ToolsModule } from './modules/tools/tools.module'
import packageJson from '../package.json'

const queryClient = new QueryClient()

export type AppProps = {
  name: string
  singleSpa: any
}

initDatadogRum({ service: packageJson.name, version: '' })

export default function Root(props: AppProps): JSX.Element {
  return (
    <AuthProvider>
      <HeapProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename='/account'>
            <Switch>
              <Route path='/login-redirect'>
                <LoginRedirect />
              </Route>
              <Route path='/unauthorized'>
                <Unauthorized />
              </Route>
            </Switch>
          </BrowserRouter>

          <BrowserRouter basename='/account/home'>
            <QueryClientProvider client={queryClient}>
              <UserDataProvider>
                <WebviewBridgeProvider>
                  <FeatureFlagsProvider>
                    <Switch>
                      <Route path='/tools'>
                        <ToolsContextProvider>
                          <MainLayout>
                            <ToolsModule />
                          </MainLayout>
                        </ToolsContextProvider>
                      </Route>
                      <Route path='/teams'>
                        <TeamsContextProvider>
                          <MainLayout>
                            <TeamsModule />
                          </MainLayout>
                        </TeamsContextProvider>
                      </Route>
                    </Switch>
                  </FeatureFlagsProvider>
                </WebviewBridgeProvider>
              </UserDataProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </ThemeProvider>
      </HeapProvider>
    </AuthProvider>
  )
}
