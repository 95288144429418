import { WebviewBridgeProvider } from '@avenue-8/platform-shared-util-frontend'
import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { TopNavBar } from '../top-nav-bar/top-nav-bar'

// eslint-disable-next-line @typescript-eslint/ban-types
export const MainLayout = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  return (
    <WebviewBridgeProvider>
      <Box maxWidth='xl' sx={{ maxWidth: '100%', paddingX: { xs: 0, md: 6 } }}>
        <TopNavBar showSearch={false} />
      </Box>
      {children}
    </WebviewBridgeProvider>
  )
}
