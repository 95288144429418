import { TeamsServicesImpl } from './teams.service.implementation'
import { TeamsServicesMockImpl } from './teams.service.mock.implementation'
import { TeamsService } from './teams.service.type'

export const TeamsServiceFactory = (): TeamsService => {
  if (process.env.REACT_APP_ENV === 'development') {
    return new TeamsServicesMockImpl()
  }

  return new TeamsServicesImpl()
}
