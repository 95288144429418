import { useEffect } from 'react'
import { useAuthContext } from '@avenue-8/platform-shared-util-frontend'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

const LoginRedirect = (): JSX.Element => {
  const { authSBL } = useAuthContext()

  useEffect(() => {
    authSBL.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        window.location.href = '/account/home/tools'
      } else {
        authSBL.requestAuthentication(true).catch(() => {
          console.error('Failed to request Authentication')
          window.location.href = '/login'
        })
      }
    })
  }, [authSBL])

  return (
    <>
      <Grid
        container
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{ width: '100%', height: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    </>
  )
}

export default LoginRedirect
