import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper'
import ToolCard from './tool-card'
import { AgentToolsDto } from '../../../domain/agent-tools.dto'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

const SwiperWrapper = styled(Box)`
  position: relative;
  left: -24px;
  width: calc(100% + 24px);

  .swiper-slide {
    height: auto;
    display: flex;
    margin-bottom: 24px;
  }
`

interface MobileSwiperProps {
  swiperBreakpoints: SwiperOptions['breakpoints']
  tools: AgentToolsDto[]
}

export default function MobileSwiper({ swiperBreakpoints, tools }: MobileSwiperProps): JSX.Element {
  const filteredTools = tools.filter((tool) => tool.showOnMobile)

  return (
    <SwiperWrapper data-testid='mobile-swiper'>
      <Swiper breakpoints={swiperBreakpoints} navigation={false}>
        {filteredTools.map((tool) => (
          <SwiperSlide key={tool.id}>
            <Box
              sx={{
                position: 'relative',
                left: '12px',
                marginRight: 2,
                px: '12px',
                pt: 3,
                width: { xs: '220px', sm: '250px' },
              }}
            >
              <ToolCard
                title={tool.title}
                description={tool.description}
                image={tool.image[0].url}
                link={tool.link}
                needsAV8Credentials={tool.needsAV8Credentials}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}
