import React, { createContext, useContext } from 'react'
import { useAuthContext, useWebviewBridgeContext } from '@avenue-8/platform-shared-util-frontend'
import { useQuery } from 'react-query'
import { AgentCategoryToolsDto } from '../domain/agent-category-tools.dto'
import { ToolsService } from '../modules/tools/services/tools.service.type'
import { ToolsServiceFactory } from '../modules/tools/services/tools.service.factory'

export type ToolsContextValue = {
  categoryTools: AgentCategoryToolsDto[]
  categoryToolsError: boolean
  onToolClick: (link: string, needsAV8Credentials: boolean) => void
}

export const useTools: () => ToolsContextValue = () => useContext(ToolsContext)

export const ToolsContext = createContext<ToolsContextValue>({
  categoryTools: [],
  categoryToolsError: false,
  onToolClick: () => {
    //
  },
})

export const ToolsContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const { agentId, token } = useAuthContext()
  const { postMessage } = useWebviewBridgeContext()

  const toolsService: ToolsService = ToolsServiceFactory()

  const { data: categoryTools = null, isError: categoryToolsError } = useQuery(
    'tools',
    async () => {
      return await toolsService.getAgentTools()
    },
    {
      onError: (error) => console.error('Failed to get tools', error),
    }
  )

  const onToolClick = (link: string, needsAV8Credentials: boolean) => {
    if (postMessage) {
      if (needsAV8Credentials) {
        const params = new URLSearchParams({ token, agentId, mbd: 'true' })
        window.location.href = `${link}?${params.toString()}`
      } else {
        window.location.href = link
      }
    } else {
      window.open(link)
    }
  }

  return (
    <ToolsContext.Provider value={{ categoryTools, categoryToolsError, onToolClick }}>
      {props.children}
    </ToolsContext.Provider>
  )
}
