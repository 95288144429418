import { Box, Skeleton, Typography } from '@mui/material'
import { useTeams } from '../../contexts/teams.context'
import { AgentRoles } from '../../domain/agent-teams.dto'
import SectionRow from './components/section-row'

export const TeamsModule = (): JSX.Element => {
  const { teams, teamError } = useTeams()

  const hasTeam = teams && Object.values(teams).some((team) => team?.length > 0)

  return (
    <Box maxWidth='xl' pl={{ xs: 3, md: 6 }} pr={{ md: 6 }} mb={10} mt={4}>
      {!!teams && (
        <Typography fontFamily='Canela' fontWeight={500} fontSize='22px' mb={4}>
          My Team
        </Typography>
      )}

      {!teamError ? (
        teams === null ? (
          <Skeleton
            variant='rectangular'
            height='40vh'
            sx={{ width: { xs: '91%', md: '100%' } }}
            data-testid='teams-skeleton'
          />
        ) : (
          <>
            {hasTeam ? (
              Object.keys(teams).map(
                (category) =>
                  teams[category] && (
                    <SectionRow key={category} teammates={teams[category]} title={AgentRoles[category]} />
                  )
              )
            ) : (
              <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 16, sm: 20 } }}>
                Seems like you don&lsquo;t have a team yet, please get in touch with us.
              </Typography>
            )}
          </>
        )
      ) : (
        <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 16, sm: 20 } }}>
          We had a problem fetching your team, please try refreshing.
        </Typography>
      )}
    </Box>
  )
}
