import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AgentToolsDto } from '../../../domain/agent-tools.dto'
import ToolCard from './tool-card'

const SwiperWrapper = styled(Box)`
  position: relative;
  left: -12px;

  .swiper-slide {
    height: auto;
    display: flex;
    margin-bottom: 24px;
  }
`

interface DesktopSwiperProps {
  swiperBreakpoints: SwiperOptions['breakpoints']
  tools: AgentToolsDto[]
}

export default function DesktopSwiper({ swiperBreakpoints, tools }: DesktopSwiperProps): JSX.Element {
  const filteredTools = tools.filter((tool) => tool.showOnDesktop)

  return (
    <SwiperWrapper data-testid='desktop-swiper'>
      <Swiper breakpoints={swiperBreakpoints} navigation={false}>
        {filteredTools.map((tool) => (
          <SwiperSlide key={tool.id} style={{ width: '270px' }}>
            <Box px='12px' pt={3} width='100%'>
              <ToolCard
                title={tool.title}
                description={tool.description}
                image={tool.image[0].url}
                link={tool.link}
                needsAV8Credentials={tool.needsAV8Credentials}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}
