import { PaginationButton, theme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { useState } from 'react'
import SwiperCore, { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AgentToolsDto } from '../../../domain/agent-tools.dto'
import ToolCard from './tool-card'

const SwiperWrapper = styled(Box)<{ $id: number }>`
  position: relative;
  left: -12px;

  .previous-${(p) => p.$id}, .next-${(p) => p.$id} {
    background-color: ${(p) => theme.palette.background.default};
    position: absolute;
    z-index: 10;
    top: calc(50% - 24px);
    margin: 0;
  }

  .previous-${(p) => p.$id} {
    left: -13px;
  }

  .next-${(p) => p.$id} {
    right: -13px;
  }

  .swiper-slide {
    height: auto;
    display: flex;
    margin-bottom: 24px;
  }
`

interface DesktopSwiperWithNavigationProps {
  id: number
  swiperBreakpoints: SwiperOptions['breakpoints']
  tools: AgentToolsDto[]
}

export default function DesktopSwiperWithNavigation({
  id,
  tools,
  swiperBreakpoints,
}: DesktopSwiperWithNavigationProps): JSX.Element {
  const [navigationInformation, setNavigationInformation] = useState<null | {
    isPreviousDisabled: boolean
    isNextDisabled: boolean
  }>(null)

  const filteredTools = tools.filter((tool) => tool.showOnDesktop)

  return (
    <SwiperWrapper $id={id} data-testid='desktop-navigation-swiper'>
      <PaginationButton
        backDisabled={navigationInformation?.isPreviousDisabled}
        backButtonProps={{
          className: `previous-${id}`,
        }}
        nextDisabled={navigationInformation?.isNextDisabled}
        nextButtonProps={{
          className: `next-${id}`,
        }}
      />
      <Swiper
        breakpoints={swiperBreakpoints}
        navigation={{
          prevEl: `.previous-${id}`,
          nextEl: `.next-${id}`,
        }}
        onSnapIndexChange={(swiper: SwiperCore) => {
          setNavigationInformation({
            isPreviousDisabled: swiper.isBeginning,
            isNextDisabled: swiper.isEnd,
          })
        }}
      >
        {filteredTools.map((tool) => (
          <SwiperSlide key={tool.id} style={{ width: '270px' }}>
            <Box px='11px' pt={3} width='100%'>
              <ToolCard
                title={tool.title}
                description={tool.description}
                image={tool.image[0].url}
                link={tool.link}
                needsAV8Credentials={tool.needsAV8Credentials}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  )
}
