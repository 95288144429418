import { useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { generateDataAttrs, getOS } from '@avenue-8/platform-shared-util-frontend'
import styled from '@emotion/styled'
import { Avatar, Box, Link, Typography } from '@mui/material'
import kebabCase from 'lodash.kebabcase'

const TeammateBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #eeeeee;
`

interface TeammateCardProps {
  photo: string
  name: string
  email: string
  phone: string
}

export default function TeammateCard({ photo, name, email, phone }: TeammateCardProps): JSX.Element {
  const { isMobile, isTablet } = useScreenSize()
  const embed = isMobile || isTablet

  const { isMacOS } = getOS()

  return (
    <TeammateBox py={3} sx={{ width: { xs: '152px', md: '192px' }, paddingX: { xs: '0px', md: '12px' } }}>
      <Avatar
        src={photo}
        alt={`${name}'s photo`}
        sx={{
          width: { xs: '96px', md: '128px' },
          height: { xs: '96px', md: '128px' },
        }}
        data-testid={`${kebabCase(name)}-photo`}
      />
      <Typography
        mt={2}
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          textAlign: 'center',
          wordBreak: 'break-word',
        }}
        data-testid={`${kebabCase(name)}-name`}
      >
        {name}
      </Typography>
      <Typography
        mt={1}
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          color: '#696B86',
          textDecoration: 'underline',
          textAlign: 'center',
          wordBreak: 'break-all',
        }}
      >
        {embed ? (
          <Link
            href={`mailto:${email}`}
            color='inherit'
            sx={{ fontWeight: 'inherit' }}
            {...generateDataAttrs({
              metaAction: `mail-to-${kebabCase(name)}`,
            })}
            data-testid={`${kebabCase(name)}-email`}
          >
            {email}
          </Link>
        ) : (
          <Link
            href={`mailto:${email}`}
            target={isMacOS ? '_self' : '_blank'}
            rel='noopener'
            color='inherit'
            sx={{ fontWeight: 'inherit' }}
            {...generateDataAttrs({
              metaAction: `mail-to-${kebabCase(name)}`,
            })}
            data-testid={`${kebabCase(name)}-email`}
          >
            {email}
          </Link>
        )}
      </Typography>
      <Typography
        mt={1}
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          color: '#696B86',
          textAlign: 'center',
          wordBreak: 'break-all',
        }}
      >
        {embed ? (
          <Link
            href={`tel://${phone.replace(/[\s)(]/g, '')}`}
            color='inherit'
            underline='always'
            sx={{ fontWeight: 'inherit' }}
            {...generateDataAttrs({
              metaAction: `call-${kebabCase(name)}-number`,
            })}
            data-testid={`${kebabCase(name)}-number`}
          >
            {phone}
          </Link>
        ) : (
          phone
        )}
      </Typography>
    </TeammateBox>
  )
}
