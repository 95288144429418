import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import Grid, { GridProps } from '@mui/material/Grid'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { Button } from '@avenue-8/platform-style-util-frontend'

const FullScreenGrid = styled(Grid)`
  height: 100vh;
  width: 100%;
`

const Container = (props: GridProps) => (
  <FullScreenGrid
    container
    spacing='8px'
    fontSize='125%'
    {...props}
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
  />
)

export const Unauthorized = (): JSX.Element => {
  const history = useHistory()
  const error: string = useMemo(() => {
    const queryParams = new URLSearchParams(history.location.search)
    return queryParams.get('error') ?? ''
  }, [history.location.search])

  switch (error) {
    case 'not-registered': {
      return (
        <Container>
          <Typography variant='button'>This e-mail is not registered in our platform.</Typography>
          <Typography variant='body2'>Please contact us if you&apos;re interested in joining Avenue 8.</Typography>
          <Button href={`${window.location.origin}/join`} sx={{ marginTop: 2 }}>
            Get in touch with us
          </Button>
        </Container>
      )
    }
    default: {
      return (
        <Container>
          <Typography variant='button'>Hmm, seems like you don&apos;t have authorization to access this.</Typography>
          <Typography variant='body2'>If you think this is a mistake: try signing in again or contact us!</Typography>
        </Container>
      )
    }
  }
}
