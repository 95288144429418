import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { useTools } from '../../contexts/tools.context'
import CategoryRow from './components/category-row'

export const ToolsModule = (): JSX.Element => {
  const { categoryTools, categoryToolsError } = useTools()

  return (
    <Box maxWidth='xl' pl={{ xs: 3, md: 6 }} pr={{ md: 6 }} mb={10} mt={4}>
      {!!categoryTools && (
        <Grid container>
          <Grid md={9} sm={12}>
            <Typography fontFamily='Canela' fontWeight={500} fontSize='22px'>
              What can we help you with?
            </Typography>
          </Grid>
        </Grid>
      )}

      {!categoryToolsError ? (
        categoryTools === null ? (
          <Skeleton variant='rectangular' height='40vh' sx={{ width: { xs: '91%', md: '100%' } }} />
        ) : (
          <>
            {categoryTools.length > 0 ? (
              categoryTools
                .filter((category) => category.tools.length > 0)
                .sort((a, b) => a.order - b.order)
                .map((category) => (
                  <CategoryRow id={category.id} title={category.title} key={category.id} tools={category.tools} />
                ))
            ) : (
              <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 16, sm: 20 } }}>
                No tools were found.
              </Typography>
            )}
          </>
        )
      ) : (
        <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 16, sm: 20 } }}>
          We had a problem fetching tools, please try refreshing.
        </Typography>
      )}
    </Box>
  )
}
