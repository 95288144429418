import React, { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { TeamsService } from '../modules/teams/services/teams.service.type'
import { TeamsServiceFactory } from '../modules/teams/services/teams.service.factory'
import { AgentTeamDto, TeamOrderObject } from '../domain/agent-teams.dto'

export type TeamsContextValue = {
  teams: AgentTeamDto
  teamError: boolean
}

export const useTeams: () => TeamsContextValue = () => useContext(TeamsContext)

export const TeamsContext = createContext<TeamsContextValue>({
  teams: null,
  teamError: false,
})

export const TeamsContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const teamsService: TeamsService = TeamsServiceFactory()

  const { data: teams = null, isError: teamError } = useQuery(
    'teams',
    async () => {
      return await teamsService.getAgentTeams().then((data) => {
        const teamsGroupedByRole = data.teamGroupedByRole
        const teamsGroupedByRoleAndOrdered = Object.values(teamsGroupedByRole).some((team) => team?.length > 0)
          ? Object.assign(TeamOrderObject, teamsGroupedByRole)
          : {}
        return teamsGroupedByRoleAndOrdered
      })
    },
    {
      onError: (error) => console.error('Failed to get teams', error),
    }
  )

  return <TeamsContext.Provider value={{ teams, teamError }}>{props.children}</TeamsContext.Provider>
}
