import styled from '@emotion/styled'
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'
import { useTools } from '../../../contexts/tools.context'

const CardMediaBox = styled(Box)`
  margin-top: 20px;
  border-radius: 0px;
`

interface ToolCardProps {
  title: string
  description: string
  link: string
  image: string
  needsAV8Credentials: boolean
}

export default function ToolCard({ title, description, image, link, needsAV8Credentials }: ToolCardProps): JSX.Element {
  const { onToolClick } = useTools()

  return (
    <Card
      onClick={() => onToolClick(link, needsAV8Credentials)}
      sx={{
        borderRadius: 0,
        cursor: 'pointer',
        boxShadow: '0px 4px 10px 0px #0000001a',
        height: '100%',
      }}
      {...generateDataAttrs({
        metaName: `${kebabCase(title)}-tool-card`,
        metaAction: `open-${kebabCase(title)}-tool-card`,
      })}
      data-testid={`${kebabCase(title)}-tool-card`}
    >
      <CardMedia
        component={() => (
          <CardMediaBox>
            <img
              src={image}
              style={{ width: '100%', borderRadius: 0 }}
              data-testid={`${kebabCase(title)}-tool-image`}
            />
          </CardMediaBox>
        )}
      />
      <CardContent sx={{ padding: 3, paddingTop: 0 }}>
        <Typography mb={1} fontWeight={500} fontSize='18px' data-testid={`${kebabCase(title)}-tool-title`}>
          {title}
        </Typography>
        <Typography
          fontWeight={400}
          fontSize='14px'
          color='secondary'
          data-testid={`${kebabCase(title)}-tool-description`}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}
