export type AgentTeamResponseDto = {
  agent: { [key: string]: any }
  teamGroupedByRole: AgentTeamDto
}

export type AgentTeamDto = {
  AgentExperienceManager?: AgentTeammateDto[]
  TransactionCoordinator?: AgentTeammateDto[]
  BrokerageSupport?: AgentTeammateDto[]
}

export type AgentTeammateDto = {
  id: number
  image: { url: string }
  name: string
  email: string
  phone: string
  role: string
  active: boolean
  order: number
}

export enum AgentRoles {
  AgentExperienceManager = 'Agent Experience Managers',
  TransactionCoordinator = 'Transaction Coordinators',
  BrokerageSupport = 'Brokerage Support',
}

//  This object is used to order the teams endpoint response by using it on Object.assign
export const TeamOrderObject = {
  AgentExperienceManager: null,
  TransactionCoordinator: null,
  BrokerageSupport: null,
}
