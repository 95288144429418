import { useEffect, useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material'

export default function AnimatedGreetings({ userData }: { userData: any }): JSX.Element {
  const [showGreetings, setShowGreetings] = useState(false)
  const [showHome, setShowHome] = useState(true)

  useEffect(() => {
    if (userData) {
      setShowHome(false)
      setTimeout(() => {
        setShowGreetings(true)
      }, 500)
    }
  }, [userData])

  return (
    <Box sx={{ flexShrink: 1 }}>
      <Typography fontFamily='Canela' fontWeight={400} sx={{ fontSize: { xs: 36, md: 48 } }}>
        <Box style={{ position: 'absolute', top: '0', left: '0' }}>
          <Collapse in={showHome} orientation='horizontal'>
            Home
          </Collapse>
        </Box>
        <Box style={{ position: 'absolute', top: '0', left: '0' }}>
          <Collapse
            orientation='horizontal'
            in={showGreetings}
            sx={{ overflow: 'hidden', maxHeight: { xs: '55px', md: '70px' } }}
          >
            Hello, {userData?.firstName}.
          </Collapse>
        </Box>
      </Typography>
    </Box>
  )
}
