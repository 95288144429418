import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import { AgentTeammateDto } from '../../../domain/agent-teams.dto'
import TeammateCard from './teammate-card'

interface SectionRowProps {
  title: string
  teammates: Array<AgentTeammateDto>
}

export default function SectionRow({ title, teammates }: SectionRowProps): JSX.Element {
  const activedTeammates = useMemo(
    () => teammates.filter((teammate) => teammate.active).sort((a, b) => a.order - b.order),
    [teammates]
  )

  return (
    <Box mb={1}>
      {activedTeammates.length > 0 ? (
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontSize: '12px',
            letterSpacing: '2px',
            fontWeight: 400,
            color: '#7a7a8e',
            mb: 4,
          }}
        >
          {title}
        </Typography>
      ) : null}

      <Box display='flex' flexWrap='wrap' data-testid='teammates-row-box'>
        {activedTeammates.length > 0
          ? activedTeammates.map((teammate, index) => (
              <Box key={teammate.id} display='flex' mr={index !== teammates.length - 1 ? 3 : 0} mb={3}>
                <TeammateCard
                  photo={teammate.image?.url}
                  name={teammate.name}
                  email={teammate.email}
                  phone={teammate.phone}
                />
              </Box>
            ))
          : null}
      </Box>
    </Box>
  )
}
