import { AuthedFetch, CONTENT_GRID_SERVICE_URL } from '@avenue-8/platform-shared-util-frontend'
import { TeamsService } from './teams.service.type'
import { AgentTeamResponseDto } from '../../../domain/agent-teams.dto'

export class TeamsServicesImpl implements TeamsService {
  async getAgentTeams(): Promise<AgentTeamResponseDto> {
    const { data } = await AuthedFetch(`${CONTENT_GRID_SERVICE_URL}/home-screen-internal-teams-by-agent`).then(
      (response) => response.json()
    )

    return data
  }
}
