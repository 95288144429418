import { AppBar, Box, InputAdornment, OutlinedInput, Stack, Toolbar } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ChangeEventHandler } from 'react'
import { UserAvatarDropDown, useScreenSize } from '@avenue-8/platform-style-util-frontend'
import { HistoryUserMenu, useUserDataContext } from '@avenue-8/platform-shared-util-frontend'
import AnimatedGreetings from './animated-greetings'

export type TopNavBarProps =
  | { showSearch?: false }
  | {
      showSearch: true
      onSearchInputChange: ChangeEventHandler<HTMLInputElement>
      searchInputValue: string
    }

export const TopNavBar = (props: TopNavBarProps): JSX.Element => {
  const userData = useUserDataContext()
  const { isMobile, isTablet } = useScreenSize()

  return (
    <Box sx={{ flexGrow: 1, position: 'sticky', top: 0, zIndex: 5 }}>
      <AppBar
        color='secondary'
        position='static'
        sx={{
          padding: { xs: '8px 24px', md: '12px 0 8px' },
          backgroundColor: { xs: `#fafaff !important`, md: 'white !important' },
        }}
      >
        <Toolbar disableGutters variant='regular'>
          <AnimatedGreetings userData={userData} />
          <Stack
            direction='row'
            spacing={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          >
            {props.showSearch && (
              <OutlinedInput
                id='outlined-templates-search-input'
                type='text'
                placeholder='Search for a tool'
                value={props.searchInputValue}
                onChange={props.onSearchInputChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            )}
            <HistoryUserMenu
              UserMenuComponent={UserAvatarDropDown}
              embed={isMobile || isTablet}
              featureString='platform-homescreen-user-menu-options'
              customSignOut={{
                label: 'Log Out',
                value: '#',
                icon: 'logout',
              }}
            />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
