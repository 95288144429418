import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import SwiperCore, { Navigation, SwiperOptions } from 'swiper'
import { theme, useScreenSize } from '@avenue-8/platform-style-util-frontend'

import DesktopSwiperWithNavigation from './desktop-swiper-with-navigation'
import DesktopSwiper from './desktop-swiper'
import MobileSwiper from './mobile-swiper'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { AgentToolsDto } from '../../../domain/agent-tools.dto'

SwiperCore.use([Navigation])

interface CategoryRowProps {
  id: number
  title: string
  tools: Array<AgentToolsDto>
}

export default function CategoryRow({ id, title, tools }: CategoryRowProps): JSX.Element {
  const { isMobile, isTablet, isSmallDesktop, isDesktop, isLargeDesktop } = useScreenSize()

  const activatedTools = useMemo(() => tools.filter((tool) => !!tool.active), [tools])

  const swiperBreakpoints = useMemo((): { [width: number]: SwiperOptions } => {
    const breakpoints = theme.breakpoints.values
    return {
      [breakpoints.xs]: {
        slidesPerView: 1.4,
        spaceBetween: 0,
      },
      [breakpoints.sm]: {
        slidesPerView: 1.5,
        spaceBetween: 0,
      },
      [breakpoints.md]: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      [breakpoints.lg]: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      [breakpoints.xl]: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    }
  }, [])

  const isDesktopResolution = (!isMobile && !isTablet && isSmallDesktop) || isDesktop || isLargeDesktop
  const isNavigationEnabled = useMemo(() => isDesktopResolution && tools.length > 4, [isDesktopResolution, tools])

  return (
    <Box mt={4}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontSize: '12px',
          letterSpacing: '2px',
          fontWeight: 400,
          color: '#7a7a8e',
        }}
      >
        {title}
      </Typography>
      <Box mt={1}>
        <Box width='100%'>
          {isDesktopResolution ? (
            isNavigationEnabled ? (
              <DesktopSwiperWithNavigation swiperBreakpoints={swiperBreakpoints} tools={activatedTools} id={id} />
            ) : (
              <DesktopSwiper swiperBreakpoints={swiperBreakpoints} tools={activatedTools} />
            )
          ) : (
            <MobileSwiper swiperBreakpoints={swiperBreakpoints} tools={activatedTools} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
