import { ToolsServicesImpl } from './tools.service.implementation'
import { ToolsServicesMockImpl } from './tools.service.mock.implementation'
import { ToolsService } from './tools.service.type'

export const ToolsServiceFactory = (): ToolsService => {
  if (process.env.REACT_APP_ENV === 'development') {
    return new ToolsServicesMockImpl()
  }

  return new ToolsServicesImpl()
}
